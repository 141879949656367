.integrations {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
    overflow-x: auto;
    gap: 1.5rem;
}

.integration {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    border-radius: 1rem;
    gap: 1rem;
    border: 1px solid $color-neutral-300;
    h3 {
        font-size: 1.125rem;
    }
}

.integration-details {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.integration-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.integration-logo {
    width: 2rem;
}