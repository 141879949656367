$layout-space-xxSmall: 0.5rem;
$layout-space-xSmall: 0.75rem;
$layout-space-small: 1rem;
$layout-space-medium: 1.5rem;
$layout-space-large: 2rem;
$layout-space-xLarge: 2.5rem;
$form-row-max-width: 40rem;
$form-row-max-width-wide: 56rem; 
$sidebar-width: 17rem;
$sidebar-width-collapsed: 4rem;

$ticket-width: 50rem;
 
$page-padding: $layout-space-medium;

:root {
  --layout-space-xxSmall: #{$layout-space-xxSmall};
  --layout-space-xSmall: #{$layout-space-xSmall};
  --layout-space-small: #{$layout-space-small};
  --layout-space-medium: #{$layout-space-medium};
  --layout-space-large: #{$layout-space-large};
  --layout-space-xLarge: #{$layout-space-xLarge};
  --form-row-max-width: #{$form-row-max-width};
  --sidebar-width: #{$sidebar-width};
  --sidebar-width-collapsed: #{$sidebar-width-collapsed};
  --page-padding: #{$page-padding};
}