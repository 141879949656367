.range-container {
    width: 100%;
}  

.range-slider {
    display: grid;
    grid-template-columns: 1fr 4rem 2rem;
    gap: 1rem;
    align-items: center;
}


 

input[type="range"] {
    -webkit-appearance: none;
    height: 8px;
    padding: 0;
    background: $color-neutral-500;
    border-radius: 1rem;
    background-image: linear-gradient($color-primary-base, $color-primary-base);
    background-repeat: no-repeat;
  }
  
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 1.25rem;
    width: 1.25rem;
    border-radius: 50%;
    background: white;
    cursor: pointer;
    box-shadow: 0 0 2px 0 #555;
    transition: box-shadow 0.5s ease-in-out;
  }
  
  input[type="range"]::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }
  
  input[type="range"]::-webkit-slider-thumb:hover {
    box-shadow: rgba($color-primary-base, 0.3) 0px 0px 0px 0.5rem;
  }
  
  input[type="range"]::-webkit-slider-thumb:active {
    box-shadow: rgba($color-primary-base, 0.3) 0px 0px 0px 0.75rem;
  }
  