.multi-picker {
    width: 220px; 
    background-color: $color-neutral-300;
    border-radius: 0.5rem; 
    border: 1.5px solid $color-neutral-400;
    .input-container:has(:disabled) input {
        color: $color-base;
    }
    ul { 
        display: grid;
        grid-template-columns: repeat(5, auto);
        gap: 0.5rem;
        padding: 1rem;
        
        li {
            aspect-ratio: 1 / 1;
            width: 1.5rem; 
            height: 1.5rem;
            font-size: 0.75rem;
            text-align: center;
            border-radius: 4px;
            display: grid;
            align-content: center;
            justify-content: center;
            span {
                color: $color-neutral-900
            }
            &:nth-child(26n) {
                grid-column: span 5;
            }
            &.used,
            &.used:hover {
                opacity: 0.75;
                span {
                    opacity: 0.25;
                    pointer-events: none;
                }
                cursor: unset;
            
            }
            &.selected,
            &.selected:hover {
                background-color: $color-primary-base;
                border-color: $color-base;
                span {
                    color: $color-base;
                }
            }
            &:hover {
                cursor: pointer;
                background-color: $color-neutral-300;
            }
        }
    }
}