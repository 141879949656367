.status-dot {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 0.5rem;
    display: inline-block;
    background-color: green;
    margin-right: 0.5rem;
}

.status-dot {
    &[data-status="online"] {
        background-color: var(--color-emerald-500);
    }
}
