@include color-mode-dark {
    .fixate-app {
        --backdrop: #{$color-neutral-100};   
    }
}
body {
    --backdrop: #{$color-neutral-100}; 
    background-color: var(--backdrop);  
}
.fixate-app {    
    display: grid;
    grid-template-columns: auto 1fr;
    height: 100vh;
    background-color: var(--backdrop);
    @include color-mode-light {
        --backdrop: #{$color-white};   
    }
    .sidebar-primary,
    main {
        height: 100%;
        overflow: auto;
    }
    main {
        container-type: inline-size;
        container-name: main;
        width: 100%;
        max-width: 100%;
        border-inline-start: $border-width-base solid var(--color-neutral-200);
    }
    @include breakpoint(phone) {
        grid-template-columns: 1fr;
        height: unset;
    }
}