// Defaults
$font-family: 'TT Hoves Variable', sans-serif;
$font-family-mono: 'Recursive', monospace;
$font-size-small: 0.75rem; 
$font-size-base: 0.875rem;
$font-size-large: 1rem;
$font-size-xLarge: 1.25rem;

  
$font-weight-light: 500;
$font-weight-base: 550;
$font-weight-bold: 650;

$line-height-base: 1.5rem;
$line-height-large: 2rem;
$letter-spacing-base: 0em; 