.queue-status-sidebar {
    background: $color-neutral-100;
    margin: $layout-space-small $layout-space-medium;
    border-radius: 0.5rem;
    padding: $layout-space-small;
    color: $color-neutral-800;
    header,
    .queue-status-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    header {
        h4 {
            font-size: $font-size-large;
            color: $color-base;
        }
    }
    ul {
        margin-block-start: 1rem;
    }
}

.queue-status-item {
    font-weight: $font-weight-light;
    color: $color-neutral-700;
    margin-block: $element-space-large;
    &:first-of-type {
        margin-block-start: 0;
    }
    &:last-of-type {
        margin-block-end: 0;
    }
    .count {
        color: $color-neutral-800;
    }
    
}
.queue-status-users-online,
.queue-status-item {
    .count {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 0.25rem;
    }
}

.queue-status-users-online { 
    color: $color-neutral-700;
}

.queue-status-stranded {
    &.stranded {
        .count { 
            color: $color-error-base;
        }
    }
}