.panel {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    background: $color-neutral-100;
    width: 45%;
    max-width: 43rem; 
    box-shadow: $box-shadow-500;
    border-left: 1px solid $color-neutral-200;
    border-top: 1px solid $color-neutral-200;
    button[title="close"] {
        position: absolute;
        top: 1rem;
        left: 0;
        transform: translateX(calc(-100% - 1rem));
    }
}
.panel-header {
    height: 4rem;
    background-color: $color-neutral-75;
    border-bottom: 1px solid $color-neutral-200;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    padding-inline: $page-padding;
    h1 {
        font-size: 1.125rem;
    }
}