input[type="file"] {
  display: none;
}
.form-item-file-upload {

  justify-content: center;

}

.file-upload-buttons {
  display: flex;
  align-items: center;
  gap: 1rem; 
  
} 

.form-item-contents {
  display: flex;
  flex-direction: row;
}

.form-item-file-upload {
  display: flex;
  flex-direction: column;

  gap: 1rem;
  width: 100%;
  margin: 0 auto;
}
.form-item-file-upload {
  .form-item-contents {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
  }
  .file-upload-actions {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
}