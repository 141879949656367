.form-toggle {
    display: grid;
    grid-template-columns: 2.5rem 1fr;
    gap: 0 1rem;
    input[type="checkbox"] {
        opacity: 0;
        position: absolute;
        width: 0.01px;
        height: 0.01px;
    }
}

// This code is garbage. I'm sorry. I'll fix it later.

.toggle {
    margin-inline: auto;
    --toggle-size: 1.5rem;
    --pip-size: calc(var(--toggle-size) * 0.75);
    display: inline-block;
    cursor: pointer;
    height: var(--toggle-size);
    width: calc(var(--toggle-size) * 1.6666);
    border-radius: var(--toggle-size);
    background-color: $color-neutral-500;
    position: relative;
    transition: background-color 0.2s ease-in-out;
    .toggle-pip {
        display: grid;
        grid-template-columns: 1fr;
        place-items: center;
        background-color: white;
        color: white;
        width: var(--pip-size);
        height: var(--pip-size);
        border-radius: var(--pip-size);
        position: absolute;
        top: calc(50% - var(--pip-size) / 2);
        left: calc((var(--toggle-size) - var(--pip-size)) / 2);
        transition: left 0.2s ease-in-out;
        transition-property: left, color;
        .icon-container {
            width:var(--pip-size);
            height: var(--pip-size);
            svg {
                width: var(--pip-size);
                height: var(--pip-size);
            }
        }
    }
    &[data-checked="true"] {
        background-color: $color-primary-base;
        .toggle-pip {
            color: $color-primary-base;
            left: calc(100% - var(--pip-size) - ((var(--toggle-size) - var(--pip-size)) / 2));
        }
    }
}
.form-toggle[data-toggle-type="checkbox"],
.form-toggle[data-toggle-type="radio"] {
    .toggle {
        width: var(--toggle-size);
        border-radius: $element-border-radius-small;
        .toggle-pip {
            background-color: transparent;
            color: $color-neutral-500;
            border-radius: $element-border-radius-small; 
        }
        &[data-checked="true"] {
            background-color: $color-primary-base;
            .toggle-pip {
                background-color: transparent;
                color: $color-white;
                left: calc(100% - var(--pip-size) - ((var(--toggle-size) - var(--pip-size)) / 2));
            }
        }
    }
}

.form-toggle[data-toggle-type="radio"] {
    .toggle {
        border-radius: 50%;
        .toggle-pip {  
            border-radius: 50%;
        }
    }
} 

[data-form-item-type="toggle"] {
    display: grid;
    grid-template-columns: 2.5rem 1fr;
    grid-template-rows: 1fr 1fr;  
    position: relative;
    label {
        cursor: pointer;
    }
    > .helpers {
        margin-top: -0.5rem;
        margin-left: 3.5rem;
        display: flex;
        flex-direction: column;
    }
}