.auth {
    display: grid;
    grid-template: 1fr / 1fr;
    height: 100vh;
    width: 100vw;
    gap: 4rem;
    align-items: center;
    justify-items: center;
    background-color: $color-neutral-200;
}
.auth-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;
}

.auth-page-form {
    width: 100%;
    max-width: var(--form-width);
    background-color: $color-neutral-100;
    border-radius: $element-border-radius-large;
    overflow: hidden;
    header,
    .form {
        padding: $page-padding;
    }
    header {
        background-color: $color-neutral-50;
        border-bottom: 1px solid $color-neutral-200;
        padding-block: 1.5rem;
        h1 {
            font-size: $font-size-xLarge;
            line-height: $line-height-large;
            margin-bottom: 0.5rem;
        }
    }
    .form {
    }
    .form-block {
        margin-block-end: 2rem;
    }
    .button {
        width: 100%;
    }
}