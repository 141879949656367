.billing-plans {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1.5rem;
    max-width: $form-row-max-width-wide;
}

.billing-plan {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    max-width: 100%;
    border: 2px solid $color-neutral-200;
    padding: 1.5rem;
    border-radius: 0.5rem;
    cursor: pointer;
    background-color: $color-neutral-75;
    .form-toggle {
        .toggle {
            margin-inline: unset;
        }
        margin-block-end: 1rem;
    }
} 