body {
    font-size: 16px;
    font-size: $font-size-base;
    font-family: $font-family;
    font-weight: $font-weight-base;
    line-height: $line-height-base;
    color: var(--color-base);
    letter-spacing: $letter-spacing-base;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    a {
        text-decoration: underline dotted;
        color: var(--color-neutral-800);
        &:hover {
            color: var(--color-neutral-900);
        }
    }
}

p {
    font-weight: $font-weight-light;
    color: var(--color-neutral-800);
}

h4 {
    font-size: 1.125rem;
}