.input-container {
    @extend .element-scale;
    --element-scale: 1rem;
    --border-width: 1.5px;
    --border-color: #{$color-neutral-300};
    --border-color-focus: #{$color-neutral-700};
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: $color-neutral-200;
    &[data-input-type="textarea"] {
        height: unset;
    }
    
    input,
    textarea {
        flex-grow: 2;
        color: var(--color-base);
    }
    &:has(:disabled) {
        input,
        textarea {
            color: $color-neutral-700;
        }
    }
}

input,
textarea {
background: transparent;
border: none;
resize: none;

}

textarea {
    min-height: 7rem;
}

[data-input-type="select"] {
    padding: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    > div {
        width: 100%;
    }
    .react-select__control {
        min-height: 0;
        height: 1.75rem;
    }

    .react-select__value-container {
        padding-left: $element-space-large;
        
    }
    .react-select__menu {
        background: $color-neutral-200;
        margin-top: 0.75rem;
        box-shadow: $box-shadow-500;
        border: $border-width-base solid $color-neutral-300;
     
        border-radius: $element-border-radius-medium;
        
    }
    .react-select__input-container {
       
    }

    .react-select__value-container {
        .react-select__placeholder,
        .react-select__single-value {
            font-weight: $font-weight-light;
        }
        .react-select__placeholder {
            color: $color-neutral-800;
        }
    }
    .react-select__option {
        padding: $element-space-medium $element-space-large;
        font-weight: $font-weight-light;
        border-bottom: 1px solid rgba(255,255,255,0.03);
        &:nth-of-type(odd) {
            
        }
    }
}