.settings-footer {
    background-color: var(--color-neutral-100);
    padding-block: $page-padding;
    margin-inline: $page-padding;
    position: sticky;
    bottom: 0;
    border-top: 1px solid #{$color-neutral-300};
}

.settings-page-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    .save {
        display: flex;
        flex-direction: row; 
        align-items: center;
        gap: $layout-space-small;
        justify-content: flex-end;
        width: 100%;
    }
} 

 