.settings-wrapper {
    display: grid;
    height: 100%;
    grid-template-columns: auto 1fr;
    > div {
        height: 100%;
    }
    @container main (max-width: 900px) {
        grid-template-columns: 1fr;
        height: unset;
    }
}

.settings-page {
    display: flex;
    flex-direction: column;
    .page-header {
        @container main (max-width: 900px) {
           display: none;
        }
    }
    .settings-footer {
        margin-top: auto;
    }
}

.settings-section {

    padding: $layout-space-medium $page-padding;
    &:first-of-type {
        padding-top: $layout-space-medium;
    }
    &:last-of-type {
        padding-bottom: $layout-space-medium;
    }
}

.settings-section-header {
    max-width: $form-row-max-width-wide;
    margin-bottom: $layout-space-medium;
    h3 {
        font-size: 1.125rem;
        margin-bottom: 0.5rem;
      
    }
}

.settings-section[data-settings-type="notifications"] {
    .form-row {
        display: grid;
        grid-template-columns: 1fr 13rem;
    }
} 