.invite-team-member {
    background: $color-neutral-200;
    border: 1px solid $color-neutral-300;
    border-radius: 1rem;
    padding: 1rem 1.5rem;
    margin: 1.5rem 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .form-item {
        max-width: 250px;
    }
    .input-container {
        background-color: $color-neutral-100;
    }
}

.invite-team-member-invite-link {
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr 2rem 2rem;
    gap: 1rem;
}