.avatar {
    --avatar-size: 2rem;
    --avatar-border-width: 0px;
    --initials-size: calc(var(--avatar-size) / 2);
    --avatar-background: var(--color-neutral-25);
    --avatar-text-color: var(--color-white); 
    display: grid;
    grid-template: 1fr / 1fr;
    width: 100%;
    max-width: var(--avatar-size);
    aspect-ratio: 1 / 1; 
    overflow: hidden;
    background-color: var(--avatar-background);
    border: var(--avatar-border-width) solid var(--color-neutral-400);
    border-radius: var(--avatar-size);
    img {
        grid-area: 1 / 1 / 1 / 1;
        object-fit: cover;
        object-position: center;
        height: 100%;
    }
    .initials {
        grid-area: 1 / 1 / 1 / 1;
        color: var(--avatar-text-color);
        font-size: var(--initials-size);
        display: grid;
        align-items: center;
        justify-items: center;
        line-height: 1em;
    }
    
}

// Sizes 

.avatar {
    &[data-size="xSmall"] {
        --avatar-size: 1.5rem;
        --initials-size: calc(var(--avatar-size) / 1.5);
        box-shadow: 0 0 0 1.5px var(--color-neutral-600);
    }
    &[data-size="small"] { 
        --avatar-size: 2.25rem;
        --initials-size: calc(var(--avatar-size) / 2);
    }
    &[data-size="medium"] {
        --avatar-size: 3rem;
        --initials-size: calc(var(--avatar-size) / 2);
    }
    &[data-size="large"] {
        --avatar-size: 4rem;
        --initials-size: calc(var(--avatar-size) / 2.5);
    }
    &[data-size="xLarge"] {
        --avatar-size: 6rem;
        --initials-size: calc(var(--avatar-size) / 2.75);
    }
    &[data-size="xxLarge"] {
        --avatar-size: 8rem;
        --avatar-border-width: 4px;
        --initials-size: calc(var(--avatar-size) / 3);
    }
}

// Colors

.avatar {
    @each $colorName, $colorHue in $ColorWheel {
        &[data-profile-color="#{$colorName}-200"] {
            --avatar-background: var(--color-#{$colorName}-200);
            --avatar-text-color: var(--color-#{$colorName}-900);
        }
        &[data-profile-color="#{$colorName}-300"] {
            --avatar-background: var(--color-#{$colorName}-300);
            --avatar-text-color: var(--color-#{$colorName}-900);
        }
        &[data-profile-color="#{$colorName}-400"] {
            --avatar-background: var(--color-#{$colorName}-400);
        }
        &[data-profile-color="#{$colorName}-500"] {
            --avatar-background: var(--color-#{$colorName}-500);
        }
        &[data-profile-color="#{$colorName}-600"] {
            --avatar-background: var(--color-#{$colorName}-600);
        }
        &[data-profile-color="#{$colorName}-700"] {
            --avatar-background: var(--color-#{$colorName}-700);
        }
        &[data-profile-color="#{$colorName}-800"] {
            --avatar-background: var(--color-#{$colorName}-800);
        }
    }    
}


// Styles

.avatar {
    &[data-style="circle"] {
        border-radius: 50%;
    }
    &[data-style="square"] {
        border-radius: 0;
    }
    &[data-style="rounded"] {
        border-radius: 15%;
    }
    &[data-style="rounded-circle"] {
        border-radius: 50%;
    }
}