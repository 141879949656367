.icon-container {
    --internal-size: 1.5rem;
    width: var(--internal-size);
    height: var(--internal-size);
    display: flex;
    flex-direction: row;
    align-items: center;
    svg {
        width: var(--internal-size);
        height: var(--internal-size);
    }
   
}

.icon-container {
    &[data-size="small"] {
        --internal-size: 1.25rem;
    }
}