.cell-contents-saved-reply {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.5rem;
    width: 100%;
    header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        span {
            display: inline-block;
        }
        .title-author {
            display: flex;
            flex-direction: row;
            gap: 0.25rem;
        }
        .title,
        .author {
            color: white;
            font-weight: $font-weight-base;
        }
        .date {
            color: $color-neutral-700;
        }
    }
    .body {
        width: 100%;
        color: $color-neutral-700;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.cell-contents-user-profile {
    display: grid;
    grid-template-columns: 3rem 1fr;
    align-items: center;
    &:has([data-size="small"]) {
        grid-template-columns: 2.25rem 1fr;
    }
    .user-profile-details {
        display: flex;
        flex-direction: column;
        justify-items: center;
        gap: 0.25rem;
       
        .name {
            color: $color-base;
            font-weight: $font-weight-base;
        }
       
    }
}

.cell-contents-tag {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.cell-contents-shortcut-key {
    white-space: nowrap;
    letter-spacing: 1px;
    font-family: $font-family-mono;
    text-align: ce;
}

.category-dot {
    --tag-dot-color: #{$color-primary-base};
    display: inline-block;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: var(--tag-dot-color);
    margin-right: 0.5rem;
    &[data-tag-type="level"] {
        --tag-dot-color: var(--color-tag-level-500);
    }
    &[data-tag-type="skill"] {
        --tag-dot-color: var(--color-tag-skill-500);
    }
    &[data-tag-type="feature-request"] {
        --tag-dot-color: var(--color-tag-feature-request-500);
    }
    &[data-tag-type="issue"] {
        --tag-dot-color: var(--color-tag-issue-500);
    }
    &[data-tag-type="team"] {
        --tag-dot-color: var(--color-tag-team-500);
    }
    &[data-tag-type="priority"] {
        --tag-dot-color: var(--color-tag-priority-500);
    }
} 

.cell-contents-ticket {
    display: grid;
    grid-template-columns: auto auto auto auto 1fr auto;
    align-items: center;
    gap: 0.75rem;
    .ticket-number {
        font-weight: 600;
        font-family: $font-family-mono;
    }
    .ticket-title {
        color: $color-base;
        font-weight: $font-weight-base;
    }
    .latest-message-type {
        margin-inline-end: -0.5rem;
    }
    .ticket-message-excerpt {
        max-height: 1rem;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .ticket-tags {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
    }

}

.cell-contents-ticket-live-activity,
.cell-contents-ticket-history-activity {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    > span {
        display: flex;
        flex-direction: row;
        align-items: center;
        white-space: nowrap;
    }
    .live-activity {
        gap: 0.75rem;
        margin-inline: 0.5rem;
        font-size: $font-size-small;
        font-weight: $font-weight-base;
    }
    .message-count {
        gap: 0.125rem;
    }
    .is-stranded {
        align-self: flex-end;
        color: $color-error-base;
    }
    .is-not-stranded {
        align-self: flex-end;
        color: $color-success-base;
    }
}

.cell-contents-reply-details {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.25rem;
    .title {
        font-size: 0.9rem;
        color: $color-base;
        font-weight: $font-weight-base;
    }
    .excerpt {
        line-height: 1.5rem;
        width: 100%; 
        overflow: hidden;
        text-overflow: ellipsis;
        
    }
}

th[data-cell-type="shortcut-key"],
td.cell-shortcut-key {
    .cell-contents { 
        padding-inline-end: 0rem;
        text-align: center;
        align-content: flex-start;
    }
    svg {
        color: $color-neutral-800;
    }
}
td.cell-shortcut-key {
    vertical-align: top;
}
th[data-cell-type="group-add-button"] {
    text-align: right;
    .cell-contents {
        display: inline-block;
        text-align: right;
        color: $color-neutral-700;
        cursor: pointer;
        &:hover {
            color: $color-neutral-900;
        }
    }
}
