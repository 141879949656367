.modal {
  background-color: $color-neutral-300;
  max-width: $ticket-width;
  margin: 0 auto;
  box-shadow: $box-shadow-500;
  border-radius: 0.5rem;
  margin-bottom: 100px;
}

.modal-header {
  display: none;
  padding: 1rem $page-padding;
  align-items: center;
  grid-template-columns: 1fr auto;
  border-bottom: 1px solid $color-neutral-400;
}

.modal-content {
  padding: $page-padding;
}

.add-tag-list {
  padding-block: 2rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: wrap;

} 
.tag-category-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
} 