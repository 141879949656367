.sidebar-settings {
    width: calc($sidebar-width - ($border-width-base * 2));
    border-inline-end: $border-width-base solid var(--color-neutral-200);
    max-width: 100%;
    .page-header {
        padding-inline: $layout-space-medium;
    }
    nav.sidebar-menu {
        padding: $layout-space-medium;
        position: relative;

        &:after {
            position: absolute;
            display: none;
            top: 0;
            right: 1rem;
            bottom: 0;
            width: 4rem;
            background: linear-gradient(to right, transparent, var(--color-neutral-100));
            content: "";
        }
    }
    .sidebar-nav-item.active {
        background-color: var(--color-neutral-300);
    }
    @container main (max-width: 900px) {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        width: 100%;
        border-inline-end: unset;
        border-block-end: $border-width-base solid var(--color-neutral-200);
        nav.sidebar-menu {
            width: 100%;
            max-width: 100%;
            overflow: hidden;
            padding-inline-start: unset;
            padding-block: unset;
            
            &:after {
                display: block;
            }
            .sidebar-nav-item {
                padding-block: $element-space-small;
            }
        }
        nav ul {
            flex-direction: row;
            max-width: 100%;
            overflow: hidden;
            overflow-x: auto;
            padding-inline-end: 3rem;
        }
    }
} 