.sidebar-menu {
    ul {
        display: flex;
        flex-direction: column;
        gap: $layout-space-xSmall;
    }
} 

.sidebar-nav-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    gap: $element-space-large;
    padding: $element-space-medium $element-space-large;
    color: var(--color-neutral-800);
    border-radius: $element-border-radius-medium;
    text-decoration: none;
    &:hover,
    &:focus {
        color: var(--color-base);
    }
    &.active {
        color: var(--color-base);
        background-color: var(--color-neutral-200);
    }
}