$emeraldHue: 164;
$redHue: 25;
$warnHue: 80;
$infoHue: 210;
$roseHue: 340;
$pinkHue: 320;
$fuchsiaHue: 300;
$purpleHue: 280;
$violetHue: 290;
$indigoHue: 230;
$blueHue: 220;
$skyHue: 220;
$cyanHue: 200;
$tealHue: 180;
$greenHue: 140;
$limeHue: 110;
$yellowHue: 90; 
$amberHue: 70;
$orangeHue: 50;

$ColorWheel: (
    "emerald": $emeraldHue,
    "red": $redHue,
    "warn": $warnHue,
    "info": $infoHue,
    "rose": $roseHue,
    "pink": $pinkHue,
    "fuchsia": $fuchsiaHue,
    "purple": $purpleHue, 
    "violet": $violetHue,
    "indigo": $indigoHue,
    "blue": $blueHue,
    "sky": $skyHue,
    "cyan": $cyanHue,
    "teal": $tealHue,
    "green": $greenHue,
    "lime": $limeHue,
    "yellow": $yellowHue,
    "amber": $amberHue,
    "orange": $orangeHue,
);

$ColorWheelTags: (
    "level": 250,
    "priority": 35,
    "skill": 220,
    "feature-request": 80,
    "issues": 270,
    "team": 140,
);

// Avatars / General
:root {
    @each $colorName, $colorHue in $ColorWheel {
        --color-#{$colorName}-200: oklch(77.00% 0.18 #{$colorHue});
        --color-#{$colorName}-300: oklch(67.00% 0.20 #{$colorHue});
        --color-#{$colorName}-400: oklch(57.00% 0.22 #{$colorHue});
        --color-#{$colorName}-500: oklch(47.00% 0.23 #{$colorHue});
        --color-#{$colorName}-600: oklch(37.00% 0.24 #{$colorHue});
        --color-#{$colorName}-700: oklch(27.00% 0.24 #{$colorHue});
        --color-#{$colorName}-800: oklch(17.00% 0.29 #{$colorHue});
        --color-#{$colorName}-900: oklch(7.00% 0.29 #{$colorHue});
    }
} 

// Tags 
:root { 
    
    @each $colorName, $colorHue in $ColorWheelTags {
        --color-tag-#{$colorName}-text: oklch(99.00% 0 #{$colorHue});

        --color-tag-#{$colorName}-background: oklch(70% 0.3 #{$colorHue});
        --color-tag-#{$colorName}-border: none;

        --color-tag-#{$colorName}-key-background: oklch(53% 0.3 #{$colorHue});
        --color-tag-#{$colorName}-background-hover: oklch(8% 0.3 #{$colorHue});
        --color-tag-#{$colorName}-key-background-hover: oklch(80% 0.3 #{$colorHue});
    }
 
}  