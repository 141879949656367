.page-header {
    padding: calc($layout-space-small - ($border-width-base / 2)) $page-padding;
    border-bottom: $border-width-base solid var(--color-neutral-200);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
   .title {
    display: flex;
    flex-direction: row;
    gap: $element-space-large;
   }
   h2 {
    font-size: $font-size-large;
   }
} 
.page-header,
.sidebar-primary-header {
    height: 68px;
}