.tabs {
    display: flex;
    flex-direction: row;
    gap: 0.75rem;
    .tab {
        padding-inline-end: 0.75rem;
        position: relative;
        &:after {
            position: absolute;
            content: "";
            display: block;
            width: 1.5px;
            background-color: $color-neutral-600;
            top: 0.5rem;
            right: 0;
            bottom: 0.5rem;
        }
        &:last-of-type {
            padding-inline-end: 0;
            &:after {
                display: none;
            }
        }
    }
}