// Defaults
a.button {
    text-decoration: none;
}

.button {
    transition: all 0.2s ease-in-out;
    --button-color: #{$color-primary-base};
    --button-color-hover: #{$color-primary-base-hover};
    --button-text-color: #{$color-white};
    --button-text-color-hover: #{$color-white};
    @extend .element-scale;
    --element-scale: 1rem;
    --border-width: 0px;
    --border-color: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    justify-content: center;
    background-color: var(--button-color);
    color: var(--button-text-color);
    border-color: var(--border-color);
    cursor: pointer;
    &:hover {
        border-color: var(--border-color-hover);
        background-color: var(--button-color-hover);
        color: var(--button-text-color-hover); 
    }
}

// icons
.button {
    &[data-icon="only"] {
        width: var(--external-size);
        padding: 0;
        svg {
            width: var(--internal-size);
            height: var(--internal-size);
        }
    }
}

// Use cases
.button {
    &[data-use="primary"] { 
        --button-color: #{$color-primary-base};
        --button-color-hover: #{$color-primary-base-hover};
        --button-text-color: #{$color-white};
    }
    &[data-use="subtle"] {
        --button-color: #{$color-neutral-200};
        --border-width: #{$border-width-base};
        --border-color: #{$color-neutral-600};
        --button-color-hover: #{$color-neutral-500};
        --button-text-color: #{$color-base};
        .label {
            opacity: 0.85;
           
        }
    }
    &[data-use="outline"],
    &[data-use="destructive"] {
        --button-color: transparent;
        --border-width: #{$border-width-base};
        --border-color: #{$color-neutral-500};
        --button-color-hover: #{$color-neutral-500};
        --button-text-color: #{$color-neutral-800};
    }
    &[data-use="destructive"] {
        --border-color: #{$color-error-base};
        --border-color-hover: #{$color-error-base-hover};
        --button-color-hover: transparent;
        --button-text-color: #{$color-error-base};
        --button-text-color-hover: #{$color-error-base-hover};
    }
    &[data-use="text"] {
        --button-color: transparent;
        --button-color-hover: transparent;
        --button-text-color: #{$color-neutral-800};
        --button-text-color-hover: #{$color-base};
    }
    
}

// states {
.button {   
    &:disabled {
        opacity: 0.5;
        pointer-events: none;
    }
    &[data-loading="true"] {
        pointer-events: none;
        position: relative;
        svg,
        .label  {
            opacity: 0;
        }
        span.loading {
            width: var(--internal-size);
            height: var(--internal-size);
            animation: spin 2s linear infinite;
            svg {
                opacity: 1;
            }
            position: absolute;
            
        }
    }
    &[data-selected="true"] {
        --button-text-color: white;
    }

}

// Sizes

.button {
    &[data-size="xSmall"]{
        .icon-container {
            --element-scale: 0rem;
            --internal-size: 1.25rem;
        }
       
        padding: 0.5rem;
        
    }
    &[data-size="small"] {
        --element-scale: 0.66666666rem;
        --internal-size: 1.25rem;
        padding: 0;
        .icon-container {
            --internal-size: 1.25rem;
        } 
    }
    &[data-size="medium"] {
        --element-scale: 1rem;
    }
    &[data-size="large"] {
        --element-scale: 1.25rem;
    }
}

// Other styles

.button {
    &[data-remove-padding="inline"] {
        padding-inline: 0;
    }
}