:root {
    --form-gap: 1.5rem;
    --form-width: #{$form-row-max-width};
}

::placeholder {
    color: $color-neutral-800;
}

.form-block {
    container-type: inline-size;
    container-name: form-block;
    width: 100%;
    max-width: var(--form-width);
    display: flex;
    flex-direction: column;
    gap: var(--form-gap);
}

.form-item {
    &[data-hidden="true"] {
        display: none;
    }
}

.form-row {
    width: 100%;
    display: flex;
    gap: var(--form-gap);
    flex-direction: row;
    @container form-block (max-width: 500px) {
        flex-direction: column;
    }
}

.form-item {
    --label-color: #{$color-base};
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: $element-space-medium;
    label {
        color: var(--label-color);
    }
    > .form-row {
        width: calc(100% - 3.5rem);
        margin-block: 0.5rem;
        margin-inline-start: 3.5rem;
        .form-row {
            width: 100%;
            margin-inline-start: 0;
        }
    } 
}
.helper-primary {
    --helper-priamry-text-weight: #{$font-weight-light};
    --helper-primary-text-color: #{$color-neutral-800};
    --helper-text-weight: #{$font-weight-light};
    color: var(--helper-primary-text-color); 
    font-weight: var(--helper-text-weight);
}
.helper-secondary {
    --helper-secondary-text-color: #{$color-neutral-700};
    --helper-text-weight: #{$font-weight-light};
    color: var(--helper-secondary-text-color);
    font-weight: var(--helper-text-weight);
}