html {
    box-sizing: border-box;
  }
  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
  button {
    font-size: inherit;
  }
  textarea {
    font-family: inherit;
  }
  img {
    max-width: 100%;
  }

*:focus {
    outline: transparent;
}

button {
    font-family: $font-family;
    font-weight: $font-weight-base;
} 
input {
    padding: 0;
    border: none;
    background-color: transparent;
}