@mixin color-mode-light {
    @media (prefers-color-scheme: light) {
        :root {  
            @content;
        }
    }
    [data-theme="light"] {
        @content; 
    }
}

@mixin color-mode-default {
    :root {  
        @content;
    } 
}

@mixin color-mode-dark {
    @media (prefers-color-scheme: dark) {
            @content;
    }
    @media (prefers-color-scheme: light) {
        [data-theme="dark"] {
            @content;
        }
    }
}
