table {
    width: 100%;
}

td {
    color: $color-neutral-800;
    font-weight: $font-weight-light;
    white-space: nowrap;
    a {
        display: block;
        text-decoration: unset;
        &:hover {
            color: unset;
        }
    }
}
 
tr {
    td,
    th {
        --cell-border-width: 1px;
        border-bottom: var(--cell-border-width) solid $color-neutral-300;
        line-height: 1em;
        vertical-align: middle;
        .cell-contents { 
            padding-inline: 1rem;
            padding-block: 1rem;
        }
        &:first-of-type {
            .cell-contents {
                padding-inline-start: $page-padding;
            }
        }
        &:last-of-type {
            .cell-contents {
                padding-inline-end: $page-padding;
            }
        }
        &.cell-ellipsis:last-of-type {
            .cell-contents {
                padding-inline-end: 1rem;
            }
        }
    }
    th {
        border-top: 1px solid $color-neutral-300;
        .cell-contents {
            padding-block: 0.5rem;
        }
    }
    &:last-child {
        td,
        th {
            border-bottom: none;
        }
    }
}
tr th {
    text-align: left;
    white-space: nowrap;
    background-color: $color-neutral-200;
    line-height: 1.5rem;
}

tr {
    th,
    td {
        &.cell-ellipsis,
        &.cell-shortcut-key {
            width: 1%;
        }
        &.cell-automation {
            width: 100%;
        }
    }
}

tr.group {
    th {
        background-color: $color-neutral-75;
    }
}

tr:has(td > a) {
    &:nth-child(even) {
        background-color: $color-neutral-75;
    } 
    &:hover {
        background-color: $color-neutral-200;
    }
}

.settings-table-narrow {
    width: 100%;
    max-width: $form-row-max-width-wide;
    overflow-y: scroll;
    border-radius: 1rem;
    overflow: hidden;
    border: 1.5px solid $color-neutral-300;
}
