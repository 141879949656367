
    
.popover {
    --popover-width: $popover-width;
    --popover-space: 1rem;
    background-color: $color-neutral-25;
    width: calc(var(--sidebar-width) - (var(--popover-space) * 2));
    position: absolute;
    
    left: calc(var(--sidebar-width) + var(--popover-space));
    bottom: var(--popover-space);
    padding: var(--popover-space);
    border-radius: 0.75rem;
    border: 1.5px solid $color-neutral-200;
}   