.sidebar-profile {
    background-color: var(--color-neutral-50);
    position: sticky;
    bottom: 0;
    padding-inline: 0;
    margin-inline: $layout-space-medium;
    margin-block-start: $layout-space-medium;
    display: grid; 
    grid-template-columns: 2.25rem 1fr auto;
    gap: 0.75rem;
    &:before {
        content: "";
        border-bottom: 1px solid $color-neutral-200;
        position: absolute;
        top: calc(-1.5rem);
        left: 0;
        right: 0;
        height: 1.5rem;
        background: linear-gradient(180deg, transparent, var(--color-neutral-50));
    }
}

.sidebar-profile-information {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    color: $color-neutral-800;
    line-height: 1.25rem;
    .status {
        color: $color-base;
        font-size: $font-size-small; 
    }
} 

.sidebar-profile-avatar,
.sidebar-profile-actions {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.sidebar-profile-avatar {
    align-items: flex-start;
    justify-content: center;
}