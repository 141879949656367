.editor-container {
    max-width: 800px;
    background-color: $color-neutral-200;
    border-radius: 0.5rem;
    border: 1.5px solid $color-neutral-400;
    overflow: hidden;
    
    .mantine-Editor-root {
        
        .tiptap.ProseMirror {
            padding-inline: unset;
            padding: 0 0.25rem;
            min-height: 6rem;
            background-color: unset;
            font-size: 0.875rem;
            font-weight: 500;
            
            ::placeholder {
                color: $color-neutral-400;
            }
        }
    }
}

.mantine-SlashMenu-root {
    background-color: $color-neutral-200;
    padding: 0.5rem;
    .mantine-Menu-label {
        background-color: $color-neutral-200;
    }
    .mantine-Menu-item {
        background-color: $color-neutral-200;
        &:hover,
        &[data-hovered="true"] {
            background-color: $color-neutral-100;
        }
    }
    .mantine-Text-root {
        max-width: 200px;
    }
    .mantine-Menu-label {
        font-size: 0.875rem;
    }
    .mantine-Menu-item {
        align-items: flex-start;
        margin-bottom: 0.25rem;
    }
    button,
    button * {
        cursor: pointer;
    }
    .mantine-Menu-itemIcon {
        background-color: unset !important;
        padding: unset !important;
    }
    .mantine-Text-root {
        font-size: 0.875rem;
        &:last-child {
            display: none;
            font-size: 0.8rem;
            font-weight: 500;
            margin-bottom: 0.5rem;
        }
    }

}

.editor-container {
    &[data-variant="transparent"] {
        background-color: transparent;
        border: unset;
    }
    ._blockContent_7sok8_22._isEmpty_7sok8_240._hasAnchor_7sok8_263 ._inlineContent_7sok8_240:before
    {
        content: "Enter text or type / for actions";
        color: $color-neutral-800;
    }
}
 