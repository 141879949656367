.tag {
    --tag-background-color: var(--color-tag-level-background);
    --tag-shortcut-key-background-color: var(--color-tag-level-key-background);
    --tag-border-color: var(--color-tag-level-border);
    --tag-text-color: var(--color-tag-level-text);

    white-space: nowrap;
    background-color: var(--tag-background-color);
    color: var(--tag-text-color);
    height: 22px;
    display: inline-flex;
    font-weight: $font-weight-base;
    flex-direction: row;
    align-items: center;
    border-radius: $element-border-radius-small;
    border: 1px solid var(--tag-border-color);
    .shortcut-key,
    .label {
        padding-inline: $element-space-medium;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    .shortcut-key {
        font-family: $font-family-mono;
        background-color: var(--tag-shortcut-key-background-color);
        border-radius: $element-border-radius-small 0 0 $element-border-radius-small;  
    }
}

$tagTypes: 
    "level",
    "priority",
    "skill",
    "feature-request",
    "issues",
    "team";

.tag {
    @each $tagType in $tagTypes {
        &[data-tag-type="#{$tagType}"] {
            --tag-background-color: var(--color-tag-#{$tagType}-background);
            --tag-shortcut-key-background-color: var(--color-tag-#{$tagType}-key-background);
            --tag-text-color: var(--color-tag-#{$tagType}-text);
        } 
    }
}

