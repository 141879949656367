
.credit-cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1.5rem;
    max-width: $form-row-max-width-wide;
}
.credit-card {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    max-width: 100%;
    border: 2px solid $color-neutral-200;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background-color: $color-neutral-75;
    &[data-is-preferred="true"] {
        border-color: $color-neutral-300;
        box-shadow:  0 0 5px 0px $color-neutral-300;
        .is-preferred {
            color: var(--color-amber-200);
        }
    }
    header {
        display: grid;
        align-items: center;
        gap: 0.5rem;
        margin-block-end: 1.5rem;
        grid-template-columns: auto 1fr auto;
        justify-content: space-between;
        .credit-card-logo {
            max-height: 1.5rem;
        }
        h3 {
            text-align: right;
        }
    }
    button {
        padding: unset;
        height: unset;
    }
    .card-details {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .credit-card-expiry {
        color: $color-neutral-800;
    }
    .credit-card-preferred {
        color: $color-neutral-800;
    }
}