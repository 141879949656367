$color-base:            var(--color-base);
$color-base-contrast:   var(--color-base-contrast);
$color-neutral-25:      var(--color-neutral-25);
$color-neutral-50:      var(--color-neutral-50);
$color-neutral-75:      var(--color-neutral-75); 
$color-neutral-100:     var(--color-neutral-100); 
$color-neutral-200:     var(--color-neutral-200);
$color-neutral-300:     var(--color-neutral-300);
$color-neutral-400:     var(--color-neutral-400);
$color-neutral-500:     var(--color-neutral-500);
$color-neutral-600:     var(--color-neutral-600);
$color-neutral-700:     var(--color-neutral-700);
$color-neutral-800:     var(--color-neutral-800);
$color-neutral-900:     var(--color-neutral-900);

$color-primary-base:        var(--color-primary-500);
$color-primary-base-hover:  var(--color-primary-700); 

$color-error-base:          var(--color-error-500);
$color-error-base-hover:    var(--color-error-700);

$color-success-base:        var(--color-success-500);
$color-success-base-hover:  var(--color-success-700);

$color-white:               var(--color-white);

@include color-mode-default {
    --color-white:          #FFFFFF;
    // ^^ This is for things that appear on dark non-neutral backgrounds - e.g. a primary button.
    --color-primary-500:    oklch(58.86% 0.255 260);
    --color-primary-700:    oklch(45.20% 0.218 260);
    --color-error-500:      #F04438;
    --color-error-700:      #D11C10;
    --color-success-500:    #17B26A;
    --color-success-700:    #17B26A;
    --color-base:           #fcfcfd;
    --color-base-contrast:  #050505;
    --color-neutral-25:     oklch(15% 0.017 270);
    --color-neutral-50:     oklch(17% 0.026 270);
    --color-neutral-75:     oklch(22% 0.018 270);
    --color-neutral-100:    oklch(23% 0.018 270);
    --color-neutral-200:    oklch(27% 0.018 270);
    --color-neutral-300:    oklch(32% 0.020 270);
    --color-neutral-400:    oklch(37% 0.022 270);
    --color-neutral-500:    oklch(41% 0.023 270);
    --color-neutral-600:    oklch(46% 0.024 270);
    --color-neutral-700:    oklch(50% 0.024 270);
    --color-neutral-800:    oklch(67% 0.029 270);
    --color-neutral-900:    oklch(83% 0.015 270);
}




// @include color-mode-dark {
//     --color-base:           #fcfcfd;
//     --color-base-contrast:  #050505;
//     --color-neutral-25:     #0b0c0f;
//     --color-neutral-50:     #101114;
//     --color-neutral-100:    #1c1e22;
//     --color-neutral-200:    #25272d;
//     --color-neutral-300:    #30343b;
//     --color-neutral-400:    #3c4049;
//     --color-neutral-500:    #474c57;
//     --color-neutral-600:    #535965;
//     --color-neutral-700:    #5e6573;
//     --color-neutral-800:    #8f96a3;
//     --color-neutral-900:    #c4c8cf;
// }