$element-space-xxLarge: 1.5rem;
$element-space-xLarge: 1rem;
$element-space-large: 0.625rem;
$element-space-medium: 0.375rem;
$element-space-small: 0.25rem;
$element-space-xSmall: 0.125rem;
$element-border-radius-xxLarge: 1.5rem;
$element-border-radius-xLarge: 1rem;
$element-border-radius-small: 0.25rem;
$element-border-radius-medium: 0.375rem;
$element-border-radius-large: 0.625rem;
$element-border-radius-xSmall: 0.1875rem;

.element-scale {
    --element-scale: 1rem;
    --external-size: calc(var(--element-scale) * 2.25);
    --internal-size: calc(var(--element-scale) * 1.5); 
    --gap: calc(var(--element-scale) * 0.625); 
    --border-width: calc(var(--element-scale) * 0.1);
    --border-radius: calc(var(--element-scale) * 0.375);
    --padding-block: calc((var(--element-scale) * 0.375) - var(--border-width));
    --padding-inline: calc((var(--element-scale) * 1) - var(--border-width));
    --font-size: calc(var(--element-scale) * 0.875);
    --icon-negative-margin: calc(var(--element-scale) * -0.375);
    --border-color-focus: inherit;
    height: var(--external-size);
    padding: var(--padding-block) var(--padding-inline);
    font-size: var(--font-size);
    border: var(--border-width) solid var(--color-neutral-300);
    border-radius: var(--border-radius);
    gap: var(--gap);
    line-height: var(--internal-size);
    svg {
        width: var(--internal-size);
        height: var(--internal-size);
    }
    svg[data-position="element-lead"] {
        margin-inline-start: var(--icon-negative-margin);
    }
    svg[data-position="element-trail"] {
        margin-inline-end: var(--icon-negative-margin);
    }
    &:focus-within {
        border-color: var(--border-color-focus);
    }
}