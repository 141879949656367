.ticket-page {
    --ticket-spacing: 1.25rem;
    padding: $page-padding * 2;
    color: $color-neutral-800;
    time {
        font-weight: $font-weight-light;
    }
}

.ticket-container {
    max-width: $ticket-width;
    margin: 0 auto;
    border-radius: 0.5rem;
    box-shadow: $box-shadow-500;
    background-color: $color-neutral-300;
} 

.message-title {
    color: $color-neutral-800;
    padding-block: 0.75rem;
    h2 {
        color: white;
        font-size: 1rem;
    }

}

.ticket-reply {
    border-bottom: unset;
}

.ticket-reply-header,
.ticket-reply-footer {
    padding-inline: 0.25rem;
    padding-block: 0.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.ticket-reply-footer {
    padding-block-end: 0.75rem;
}

.ticket-actions,
.ticket-tag-reply,
.ticket-reply-augments,
.message-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 0.125rem;
}
.ticket-reply-actions {
    padding-block: 0.5rem;
}

// Adds line separator between the reply and add tag actions, and attachments, emoji and gif. 
.ticket-tag-reply {
    position: relative;
    padding-inline-end: 0.5rem;
    margin-inline-end: 0.5rem;
    &:after {
        position: absolute;
        content: "";
        display: block;
        width: 1.5px;
        background-color: $color-neutral-600;
     
        top: 0.5rem;
        right: 0;
        bottom: 0.5rem;
    }
}

.ticket-reply-meta {
    font-weight: $font-weight-light;
    color: $color-neutral-800;
    .reply-type,
    .highlight {
        font-weight: $font-weight-base;
    }
    .highlight {
        color: white;
    }
    .reply-type {
        &.draft-reply {
            color: $color-success-base;
        }
    }
}

.ticket-message {
    padding-block: 1rem;
    p {
        color: white;
        + p {
            margin-block-start: 0.75rem;
        }
    }
    .message-signature {
        margin-block-start: 1rem;
        color: $color-neutral-800;
        p {
            color: $color-neutral-800;
        }
    }
}

.ticket-header {
    padding-inline: 1.25rem;
    padding-block: 1rem;
    border-block-end: 1.5px solid $color-neutral-400;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .ticket-meta,
    .ticket-status,
    .ticket-viewers {
        display: flex;
        flex-direction: row;
        gap: 0.5rem;
    }
}


.ticket-message,
.message-title,
.ticket-reply,
.ticket-tag-added {
    display: flex;
    justify-content: space-between;
    margin-inline: 1.25rem;
    padding-block: 1rem;
    border-block-end: 1.5px solid $color-neutral-400;
}

.ticket-reply,
.ticket-message {
    flex-direction: column;
}

.ticket-reply {
    padding-block: 0.25rem;
}

.ticket-message {
    display: flex;
    flex-direction: column;
    time {
        text-align: right;
        margin-bottom: -1.5rem;
       
    }
}

.ticket-tag-added {
    .message {
        font-weight: $font-weight-light;
        display: flex;
        flex-direction: row;
        gap: 0.33rem;
        .user {
            font-weight: $font-weight-base;
            color: white;
        }
    }
}

.tag-list {
    display: flex;
    flex-direction: row;
    gap: 0.33rem;
}