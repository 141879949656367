.sidebar {
  container-type: inline-size;
  container-name: sidebar;
  width: $sidebar-width;
  display: flex;
  flex-direction: column;
}

.sidebar-primary {
  background-color: var(--color-neutral-50);
  @include breakpoint(phone) {
    width: 100%;
    max-width: 100%;
  }
}

.sidebar-primary-header,
nav[data-menu-location="sidebar-primary"],
.sidebar-queue-status,
.sidebar-profile {
  padding: $layout-space-small $layout-space-medium;
}

[data-menu-name="secondary"][data-menu-location="sidebar-primary"] {
  margin-top: auto;
}
.sidebar-primary-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.primary-sidebar-top {
  display: flex;
  flex-direction: column;
  flex-grow: 2;
}

// Collapsed styles

@include sidebar-closed {
  max-width: $sidebar-width-collapsed;
  @include breakpoint(phone) {
    max-width: 100%;
    .sidebar-menu,
    .color-mode-setter,
    .sidebar-queue-status,
    .sidebar-profile {
      display: none;
    }
  }
}



@container sidebar (max-width: 200px) {
  .fixate-logo {
    display: none;
  }
  .sidebar-primary-header,
  nav[data-menu-location="sidebar-primary"],
  .sidebar-queue-status,
  .sidebar-profile {
    padding-inline: $layout-space-xSmall;
  }
  .sidebar-nav-item {
    .label {
      display: none;
    }
  }
  .sidebar-primary-header {
    flex-direction: column;
    gap: $layout-space-medium;
  }
}
