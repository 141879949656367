.react-tooltip {
    position: relative;
    background-color: $color-neutral-25;
    color: white;
    padding: 0.5rem;
    border-radius: 0.5rem;
    box-shadow: 0 0 3px rgba(255,255,255, 0.3);
    h4 {
        font-size: 1.125rem;
    }
}

.react-tooltip-arrow {
    z-index: -1;
    width: 1rem;
    height: 1rem;
    transform: rotate(45deg);
}

